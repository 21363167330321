import React from "react";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FeedIcon from "@mui/icons-material/Feed";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import "./Features.css";

const Features = () => {
  return (
    <div className="dgf-container">
      <h5>Enter the Realm of Esports and Gaming</h5>
      <h1>Unleashing Heroes in the Virtual Arena</h1>
      <div className="dgf-c">
        <div className="dgf">
          <VideogameAssetIcon className="icon" />
          <h4>Game Top-Up</h4>
          <p>Effortlessly recharge any game on our platform.</p>
        </div>
        <div className="dgf">
          <NewspaperIcon className="icon" />
          <h4>Gaming Updates</h4>
          <p>Stay informed with the hottest trends and news in gaming.</p>
        </div>
        <div className="dgf">
          <SlowMotionVideoIcon className="icon" />
          <h4>Game Live Stream</h4>
          <p>Tune in to live game action directly on our platform.</p>
        </div>
        <div className="dgf">
          <EmojiEventsIcon className="icon" />
          <h4>Tournament Alerts</h4>
          <p>Stay updated on all upcoming gaming tournaments.</p>
        </div>
      </div>
    </div>
  );
};

export default Features;
